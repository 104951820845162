<template>
  <FormField
    v-if="edit && (edit.type === 'switch' || editMode !== 'show')"
    :service="data"
    :field="edit.field || col.field"
    :type="edit.type"
    :options="edit.options"
    :optionValue="edit.optionValue"
    :optionLabel="edit.optionLabel"
    :trueValue="edit.trueValue"
    :falseValue="edit.falseValue"
    @change="onCellEdit(data, edit.field || col.field)"
    margin="0"
  />
  <slot v-else-if="col.slot" :data="data"></slot>
  <GridBtn v-else-if="col.actions" :actions="col.actions" :service="data" />
  <!-- <ActionBtn v-else-if="col.actions" :actions="col.actions" :service="data" @load="$emit('load')" /> -->
  <Link v-else-if="col.to" :to="col.to" :data="data">
    {{ colContent }}
  </Link>
  <div v-else :class="edit ? 'custom-underline' : ''">
    {{ colContent || emptyText }}
  </div>
</template>

<script>
import FormField from "./form/FormField.vue";
import Link from "./Link.vue";
import ActionBtn from "./ActionBtn.vue";
import Str from "../../utils/Str";
import GridBtn from "./GridBtn.vue";
export default {
  components: {
    FormField,
    Link,
    ActionBtn,
    GridBtn,
  },
  props: {
    col: Object,
    data: Object,
    editMode: String,
  },
  emits: ["load"],
  data() {
    return {
      Str: Str,
    };
  },
  computed: {
    value() {
      // Берем значение, если оно составное, т.е. передано как массив из вложенных ключек к переменной элемента сервиса
      if (typeof this.col.field === "object") {
        let value = this.data;

        for (let f of this.col.field) {
          if (value[f] === undefined) {
            return null;
          }
          value = value[f];
        }

        return value;
      }

      return this.data[this.col.field];
    },
    colValue() {
      // Берем форматированное значение
      return Str.format(
        this.col.value ? this.col.value(this.data) : this.value,
        this.col.format
      );
    },
    colContent() {
      // Берем указанный контент, который оборачивает форматированное ранее значениеЋ
      return this.col.content
        ? this.col.content(this.data, this.colValue)
        : this.colValue;
    },
    edit() {
      return typeof this.col.edit === "function"
        ? this.col.edit(this.data)
        : this.col.edit;
    },
    emptyText() {
      return typeof this.col.emptyText === "function"
        ? this.col.emptyText(this.data)
        : this.col.emptyText;
    },
  },
  methods: {
    async onCellEdit(data, field) {
      if (typeof field === "object") {
        await data.save([field[0]]);
      } else {
        await data.save([field]);
      }
    },
  },
};
</script>
