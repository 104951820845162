<template>
  <div style="" class="d-flex">
    <div v-for="item in actions" :key="item.id">
      <router-link :to="{ name: item.routeName, params: { id: service.id } }"
        ><div
          v-if="item.action === 'show'"
          class="pi pi-eye text-center text-info"
          style="width: 30px; font-size: 1rem !important"
        ></div
      ></router-link>
      <router-link :to="{ name: item.routeName, params: { id: service.id } }"
        ><div
          v-if="item.action === 'edit'"
          class="pi pi-pencil text-center text-warning"
          style="width: 30px; font-size: 1rem !important"
        ></div
      ></router-link>

      <div
        v-if="item.action === 'delete'"
        class="pi pi-trash text-center text-danger"
        style="width: 30px; font-size: 1rem !important"
        @click="service.delete(item.id)"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    actions: Array | Function,
    service: Object,
  },
  emits: ["load"],
  data() {
    return {
      length: 0,
    };
  },
};
</script>