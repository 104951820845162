<template>
  <div v-if="!error">
    <DataTable
      :value="items"
      :lazy="true"
      :paginator="
        paginator ? service && service.metaPerPage !== undefined : paginator
      "
      :rows="service ? service.metaPerPage : items.length"
      :totalRecords="service ? service.metaTotal : items.length"
      :loading="loading"
      v-model:filters="filters"
      v-model:selection="selectedItems"
      dataKey="id"
      @page="onPage"
      @sort="onSort"
      @filter="onFilter"
      filterDisplay="menu"
      responsiveLayout="scroll"
      v-model:first="offset"
      :rowClass="rowClass"
      :paginatorTemplate="
        paginatorTemplate ||
        'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
      "
      :rowsPerPageOptions="[15, 50, 100, 500]"
      :currentPageReportTemplate="
        'Показано' + ' {first} - {last} ' + 'из' + ' {totalRecords}'
      "
      editMode="cell"
      class="editable-cells-table"
      :rowHover="true"
      @row-select-all="onRowSelectAll"
      @update:selection="onUpdateSelection"
      :resizableColumns="true"
      columnResizeMode="fit"
      :rowGroupMode="rowGroupMode"
      :groupRowsBy="groupRowsBy"
    >
      <template #empty> Нет данных </template>
      <!-- <Column v-if="massActions" selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
      <Column
        v-for="col of columns"
        :field="fieldStr(col.field)"
        :header="col.header"
        :ref="fieldStr(col.field)"
        :sortable="col.sortable"
        :sortField="col.sortField"
        :key="fieldStr(col.field)"
        :class="col.class"
      >
        <template v-if="col.filter" #filter="{ filterCallback }">
          <FormField
            :service="service"
            :field="col.filter.field || col.field"
            :label="col.filter.label"
            :type="col.filter.type"
            :options="col.filter.options"
            :optionValue="col.filter.optionValue"
            :optionLabel="col.filter.optionLabel"
            :optionGroupLabel="col.filter.optionGroupLabel"
            :optionGroupChildren="col.filter.optionGroupChildren"
            :selectionMode="col.filter.selectionMode"
            @input="filterCallback"
          />
        </template>
        <template #filterclear></template>
        <template #filterapply></template>
        <template v-if="col.edit" #editor="{ index }">
          <GridViewCell :col="col" :data="items[index]" @load="load">
            <slot v-if="col.slot" :name="col.slot" :data="items[index]"></slot>
          </GridViewCell>
        </template>
        <template #body="{ index }">
          <GridViewCell
            v-for="(colItem, colIndex) in col.items || [col]"
            :key="colIndex"
            :col="colItem"
            :data="items[index]"
            @load="load"
            editMode="show"
            :class="colItem.class"
            @click="colItem.onClick ? colItem.onClick(items[index]) : () => {}"
          >
            <slot
              v-if="colItem.slot"
              :name="colItem.slot"
              :data="items[index]"
            ></slot>
          </GridViewCell>
        </template>
      </Column>
      <template v-if="footer" #footer>
        {{ footerText }}
      </template>
    </DataTable>

    <SearchBar
      v-if="search"
      :service="service"
      :search="search"
      @filter="onFilter"
    />
  </div>
  <SpeedDialBtn v-if="actions" :actions="actions" :service="service" />
  <div v-else>
    <!-- <Message severity="warn" :closable="false">{{ error }}</Message> -->
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
// import Message from 'primevue/message';
import SearchBar from "./SearchBar.vue";
import FormField from "./form/FormField.vue";
import Link from "./Link.vue";
import GridViewCell from "./GridViewCell.vue";
import Str from "/src/utils/Str";
import SpeedDialBtn from "@/components/ui/SpeedDialBtn";

export default {
  components: {
    DataTable,
    Column,
    // Message,
    SearchBar,
    FormField,
    Link,
    GridViewCell,
    SpeedDialBtn,
  },
  props: {
    service: Object,
    columns: Array,
    search: Array,
    synchronization: Boolean,
    actions: Array | Boolean,
    massActions: Array,
    paginator: {
      type: Boolean,
      default: true,
    },
    paginatorTemplate: String,
    rowClass: Function,
    footer: Boolean,
    footerText: String | Number,
    isAutoLoad: {
      type: Boolean,
      default: true,
    },
    rowGroupMode: String,
    groupRowsBy: String,
    modelValue: Array,
  },
  data() {
    return {
      items: this.modelValue,
      filters: {},
      loading: false,
      offset: 0,
      awaitFilter: false,
      visibleSearch: false,
      Str: Str,
      selectedItems: [],
      error: null,
    };
  },
  async created() {
    if (!this.isAutoLoad || !this.service) {
      return;
    }

    if (this.service.parseRequest) {
      this.service.parseRequest();
    }

    // for paginator
    if (this.service.page) {
      this.offset = (this.service.page - 1) * this.service.metaPerPage;
    }

    // for filters
    for (let i in this.columns) {
      if (this.columns[i].filter) {
        this.filters[this.columns[i].field] = {};
      }
    }

    await this.load();
  },
  methods: {
    async load(routerPush) {
      this.loading = true;

      this.selectedItems = [];

      this.items = await this.service.all();
      this.$emit("update:modelValue", this.items);

      this.error = null;
      if (Object.keys(this.service.errors).length) {
        this.error = this.service.errors[Object.keys(this.service.errors)[0]];
      }

      if (routerPush) {
        this.$router.push({ query: this.service.prepareRequest(true) });
      }

      this.loading = false;

      this.$emit("afterLoad", event);
    },
    reset() {
      this.items = [];
      this.$emit("update:modelValue", this.items);
    },
    async onFilter(e) {
      var awaitFilter = (this.awaitFilter = Math.random());
      // ставим ожидание, чтобы при введении значения в фильтре на каждую букву не отправлять
      setTimeout(async () => {
        if (awaitFilter === this.awaitFilter) {
          delete this.service.page;
          await this.load(true);
        }
      }, 1000);
    },
    async onPage(e) {
      this.service.page = e.page + 1;
      this.service.metaPerPage = e.rows;

      await this.load(true);
    },
    async onSort(e) {
      delete this.service.page;
      this.service.sort = e.sortField;
      this.service.direction = e.sortOrder === 1 ? "asc" : "desc";

      await this.load(true);
    },
    onRowSelectAll(e) {
      // Это нужно, т.к. выбор всех галочек в primeview проверяет общее кол-во элементов, а не те, что на странице
      // поэтому мы добавляем пустые элементы к this.items, а в onUpdateSelection убираем их
      if (
        this.service.metaPerPage < this.service.metaTotal &&
        e.data.length < this.service.metaTotal
      ) {
        let countDiff = this.service.metaTotal - this.service.metaPerPage;
        for (let i = 0; i < countDiff; i++) {
          e.data.push([]);
        }
      }
    },
    onUpdateSelection(data) {
      this.service._selectedItems = Object.assign([], data);

      // Это нужно, т.к.... см. onRowSelectAll
      if (
        this.service.metaPerPage < this.service.metaTotal &&
        this.service._selectedItems.length >= this.service.metaTotal
      ) {
        let countDiff = this.service.metaTotal - this.service.metaPerPage;
        for (let i = 0; i < countDiff; i++) {
          this.service._selectedItems.pop();
        }
      }
    },
    fieldStr(field) {
      if (typeof field === "object") {
        return field.join(".");
      }

      return field;
    },
  },
};
</script>

<style>
.p-column-filter-matchmode-dropdown {
  display: none !important;
}
.p-column-filter-buttonbar {
  padding: 0 !important;
}
</style>